<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <b-button class="history-button" variant="secondary" size="sm" @click="state.historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('project.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('project.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <AvatarBanner 
          v-if="isAvatarBannerVisible" 
          v-model="avatarBanner" 
          :readOnly="isAvatarBannerReadOnly" 
          :baseAvatarIcon="['fad','chart-network']" transforms="shrink-6 left-2" @status="avatarBannerStatus"
        />
        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('project.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('project.field.name')"
                    data-vv-name="project.name"
                    data-vv-delay="500"
                    v-model="project.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    trim
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'project.name')">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('project.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['name'] != null">
              <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="project.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="project.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['identifier'] != null">
              <b-col v-for="(field, index) in customFieldMap['identifier']" :key="'identifier'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="customFieldMap['default'] != null">
              <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
    
            
            <b-col v-if="isCompanyVisible" cols="12" md="8" class="pr-0">
              <b-form-group>
                <label class="mr-1">{{ $t(`project.field.company`) }}</label>
                <button v-if="!isCompanyReadOnly" id="COMPANY_ADD" class="btn-action" @click="companySelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="COMPANY_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.company_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="company" :readOnly="isCompanyReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="companyBadgeRemove(index)" @badgeClick="companyBadgeClick(item.uuId)"
                      :text="item.name" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isCompanyReadOnly" />
                    </template>
                </BadgeGroup>
                <b-form-invalid-feedback class="mt-1 alert-danger form-field-alert" :class="{ 'd-block': showCompanyError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staff.company') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['companies'] != null">
              <b-col v-for="(field, index) in customFieldMap['companies']" :key="'companies'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="project.color" :update="updatedColor"/>
              </div>
            </b-col>

            <template v-if="customFieldMap['color'] != null">
              <b-col v-for="(field, index) in customFieldMap['color']" :key="'color'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isLocationVisible">
              <b-form-group class="mb-0">
                <label class="mr-1">{{ $t('project.field.location') }}</label>
                <button v-if="!isLocationReadOnly" id="LOCATION_ADD" class="btn-action" @click="locSelectorOpen"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="LOCATION_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.location_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="location" class="mb-3" :readOnly="isLocationReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="locationBadgeRemove(index)" @badgeClick="locationBadgeClick(item.uuId)"
                      :text="item.name" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index" 
                      :readOnly="isLocationReadOnly" />
                    </template>
                </BadgeGroup>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showLocationError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('locSelector.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['locations'] != null">
              <b-col v-for="(field, index) in customFieldMap['locations']" :key="'locations'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isCustomerVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`project.field.customer`) }}</label>
                <button v-if="!isCustomerReadOnly" id="CUSTOMER_ADD" class="btn-action" @click="customerSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="CUSTOMER_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.customer_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="customers" :readOnly="isCustomerReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="customerBadgeRemove(index)" @badgeClick="customerBadgeClick(item.uuId)"
                      :text="item.name" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isCustomerReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['customers'] != null">
              <b-col v-for="(field, index) in customFieldMap['customers']" :key="'customers'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isPriorityVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.priority')" label-for="project-priority">
                <div>
                  <select id="project-priority" class="custom-select" v-model="project.priority" :disabled="isPriorityReadOnly">
                    <template v-for="(opt, index) in priorityOptions">
                      <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                    </template>
                  </select>
                </div>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['priority'] != null">
              <b-col v-for="(field, index) in customFieldMap['priority']" :key="'priority'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isStageVisible" cols="12" md="6" class="pr-0">
              <b-form-group>
                <label class="mr-1">{{ $t(`project.field.status`) }}</label>
                <button v-if="!isStageReadOnly" id="STATUS_ADD" class="btn-action" @click="statusSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="STATUS_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.status_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="statuses" :readOnly="isStageReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="statusBadgeRemove(index)" @badgeClick="statusBadgeClick(item.uuId)"
                      :text="item.name" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index" 
                      :readOnly="isReadOnly || !canEdit(permissionName, ['STAGE'])" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['status'] != null">
              <b-col v-for="(field, index) in customFieldMap['status']" :key="'status'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isScheduleStartVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.scheduleStart')" label-for="startDate">
                <b-form-datepicker id="startDate" v-model="project.scheduleStart" class="mb-2"
                  :max="project.scheduleFinish"
                  value-as-date
                  today-button
                  reset-button
                  close-button
                  hide-header
                  :label-today-button="$t('date.today')"
                  :label-reset-button="$t('date.reset')"
                  :label-close-button="$t('date.close')"
                  today-button-variant="primary"
                  reset-button-variant="danger" 
                  close-button-variant="secondary"
                  :readonly="isScheduleStartReadOnly"
                >
                  <template v-slot:button-content="{ }">
                    <font-awesome-icon :icon="['far', 'calendar-days']" />
                  </template>
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            
            <template v-if="customFieldMap['scheduleStart'] != null">
              <b-col v-for="(field, index) in customFieldMap['scheduleStart']" :key="'scheduleStart'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isScheduleFinishVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.scheduleFinish')" label-for="endDate">
                <b-form-datepicker id="endDate" v-model="project.scheduleFinish" class="mb-2"
                  :min="project.scheduleStart"
                  value-as-date
                  today-button
                  reset-button
                  close-button
                  hide-header
                  :label-today-button="$t('date.today')"
                  :label-reset-button="$t('date.reset')"
                  :label-close-button="$t('date.close')"
                  today-button-variant="primary"
                  reset-button-variant="danger" 
                  close-button-variant="secondary"
                  :readonly="isScheduleFinishReadOnly"
                >
                  <template v-slot:button-content="{ }">
                    <font-awesome-icon :icon="['far', 'calendar-days']" />
                  </template>
                </b-form-datepicker>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['scheduleFinish'] != null">
              <b-col v-for="(field, index) in customFieldMap['scheduleFinish']" :key="'scheduleFinish'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isFixedDurationVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.fixedDuration')" label-for="project-fixed-duration">
                <b-input-group>
                  <b-input-group-prepend v-if="!isFixedDurationReadOnly">
                    <b-button id="FIXED_DURATION_SUBTRACT" @click.prevent="fixedDurationAddMinus(-1)">
                      <font-awesome-icon :icon="['far', 'minus']"/>
                      <b-popover
                        target="FIXED_DURATION_SUBTRACT"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.duration_subtract')">
                      </b-popover>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input id="project-fixed-duration" type="text"
                    @keydown="onFixedDurationKeyDown($event)" 
                    @keyup="onFixedDurationKeyUp($event)"
                    @change="onFixedDurationChange"
                    :data-vv-as="$t('project.field.fixedDuration')"
                    data-vv-name="project.fixedDuration"
                    :class="fixedDurationClass"
                    data-vv-delay="500"
                    v-model="project.fixedDuration" 
                    :readonly="isFixedDurationReadOnly"
                    >
                  </b-form-input>
                  <b-input-group-append v-if="!isFixedDurationReadOnly">
                    <b-button id="FIXED_DURATION_ADD" @click.prevent="fixedDurationAddMinus(1)">
                      <font-awesome-icon :icon="['far', 'plus']"/>
                      <b-popover
                        target="FIXED_DURATION_ADD"
                        placement="top"
                        triggers="hover"
                        :content="$t('task.button.duration_add')">
                      </b-popover>
                    </b-button>
                  </b-input-group-append>
                  <b-input-group-append v-if="fixedDurationAlert">
                    <b-input-group-text id="fixed-duration-alert" class="fixed-duration-alert">
                      <font-awesome-icon :icon="['far', 'circle-exclamation']"/>
                    </b-input-group-text>
                    <b-popover target="fixed-duration-alert" triggers="hover" placement="topleft">
                      {{ fixedDurationAlertMsg }}
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showFixedDurationError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('fixedDuration') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['fixedDuration'] != null">
              <b-col v-for="(field, index) in customFieldMap['fixedDuration']" :key="'fixedDuration'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isDurationVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.estimatedDuration')" label-for="project-duration">
                <b-input-group>
                  <b-form-input id="project-duration" type="text"
                    v-model="project.estimatedDuration" 
                    readonly
                    >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['estimatedDuration'] != null">
              <b-col v-for="(field, index) in customFieldMap['estimatedDuration']" :key="'estimatedDuration'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
            
            <b-col cols="12" md="6" class="pr-0" v-if="isActualDurationVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`project.field.actualDuration`) }}</label>
                <b-input-group>
                  <b-form-input id="project-actual-duration" type="text"
                    v-model="project.actualDuration" 
                    readonly
                    >
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['actualDuration'] != null">
              <b-col v-for="(field, index) in customFieldMap['actualDuration']" :key="'actualDuration'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isAutoSchedulingVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.autoScheduling')" label-for="project-autoschedule">
                <b-form-select v-model="project.autoScheduling" id="project-autoschedule" :disabled="isAutoSchedulingReadOnly">
                  <template>
                    <option :value="true">{{ $t('project.autoschedule.auto') }}</option>
                    <option :value="false">{{ $t('project.autoschedule.manual') }}</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['autoScheduling'] != null">
              <b-col v-for="(field, index) in customFieldMap['autoScheduling']" :key="'autoScheduling'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isScheduleModeVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('project.field.scheduleMode')" label-for="scheduleMode">
                <b-form-select v-model="project.scheduleMode" id="scheduleMode"
                  :options="optionscheduleMode" :disabled="isScheduleModeReadOnly">
                </b-form-select>
                <b-form-text>{{ project.scheduleMode == 'ASAP' ? $t('project.select_schedule_hint_asap') :
                                project.scheduleMode == 'ALAP' ? $t('project.select_schedule_hint_alap') :
                                ''
                            }}
                </b-form-text>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['scheduleMode'] != null">
              <b-col v-for="(field, index) in customFieldMap['scheduleMode']" :key="'scheduleMode'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" class="pr-0" v-if="isDescriptionVisible">
              <b-form-group :label="$t('project.field.description')" label-for="description">
                <b-form-textarea id="description" 
                  :placeholder="isDescriptionReadOnly? '' : $t('project.placeholder.description')"
                  :data-vv-as="$t('project.field.description')"
                  data-vv-name="project.description"
                  data-vv-delay="500"
                  v-model="project.description"
                  :max-rows="6"
                  v-validate="{ max: maxDescriptionLength }"
                  :readonly="isDescriptionReadOnly"
                  trim
                  :rows="3"/>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('project.description') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['description'] != null">
              <b-col v-for="(field, index) in customFieldMap['description']" :key="'description'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isTagVisible">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['tags'] != null">
              <b-col v-for="(field, index) in customFieldMap['tags']" :key="'tags'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col v-if="isFixedCostVisible || isFixedCostNetVisible" cols="12" md="6" class="pr-0">
              <b-form-select class="title-dropdown mb-1" v-model="fixedCostMode">
                <option v-if="isFixedCostVisible" value="gross">{{ $t('project.field.fixedCost') }}</option>
                <option v-if="isFixedCostNetVisible" value="net">{{ $t('project.field.fixedCostNet') }}</option>
              </b-form-select>
              <b-form-group>
                <b-input-group>
                  <b-form-input id="cost" type="number" :min="0.00" :step="1" lazy-formatter
                    v-if="fixedCostMode === 'gross'"
                    :formatter="floatFormatter(null, 0.00)"
                    v-model="project.fixedCost"
                    :readonly="isFixedCostReadOnly">
                  </b-form-input>
                  <b-form-input id="costNet" type="number" :min="0.00" :step="1" lazy-formatter
                    v-if="fixedCostMode === 'net'"
                    :formatter="floatFormatter(null, 0.00)"
                    v-model="project.fixedCostNet"
                    readonly>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['fixedCost'] != null">
              <b-col v-for="(field, index) in customFieldMap['fixedCost']" :key="'fixedCost'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isCurrencyCodeVisible" cols="12" md="6" class="pr-0">
              <b-form-group :label="$t('task.field.currencyCode')">
                <b-input-group>
                  <select id="project-priority" class="custom-select" v-model="project.currencyCode" :disabled="isCurrencyCodeReadOnly">
                    <template v-for="(opt, index) in currencyOpts">
                      <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                    </template>
                  </select>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showCurrencyError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('project.currencyCode') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['currencyCode'] != null">
              <b-col v-for="(field, index) in customFieldMap['currencyCode']" :key="'currencyCode'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
  
            <b-col v-if="isEstimatedCostVisible || isEstimatedCostNetVisible" cols="12" md="6" class="pr-0">
              <b-form-select class="title-dropdown mb-1" v-model="estimatedCostMode">
                <option v-if="isEstimatedCostVisible" value="gross">{{ $t('project.field.estimatedCost') }}</option>
                <option v-if="isEstimatedCostNetVisible" value="net">{{ $t('project.field.estimatedCostNet') }}</option>
              </b-form-select>
              <b-form-group>
                <b-input-group>
                  <b-form-input id="estimatedCost"
                    v-if="estimatedCostMode === 'gross'"
                    :value="estimatedCost"
                    readonly>
                  </b-form-input>
                  <b-form-input id="estimatedCostNet"
                    v-if="estimatedCostMode === 'net'"
                    :value="estimatedCostNet"
                    readonly>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            
            <template v-if="customFieldMap['estimatedCost'] != null">
              <b-col v-for="(field, index) in customFieldMap['estimatedCost']" :key="'estimatedCost'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col v-if="isActualCostVisible || isActualCostNetVisible" cols="12" md="6" class="pr-0">
              <b-form-select class="title-dropdown mb-1" v-model="actualCostMode">
                <option v-if="isActualCostVisible" value="gross">{{ $t('project.field.actualCost') }}</option>
                <option v-if="isActualCostNetVisible" value="net">{{ $t('project.field.actualCostNet') }}</option>
              </b-form-select>
              <b-form-group>
                <b-input-group>
                  <b-form-input id="actualCost"
                    v-if="actualCostMode === 'gross'"
                    :value="actualCost"
                    :class="{ colorRed: actualCostClass, 'forced-border-radius': actualCostAlert }"
                    readonly>
                  </b-form-input>
                  <b-form-input id="actualCostNet"
                    v-if="actualCostMode === 'net'"
                    :value="actualCostNet"
                    :class="{ colorRed: actualCostClass, 'forced-border-radius': actualCostAlert }"
                    readonly>
                  </b-form-input>
                  <b-input-group-append v-if="actualCostAlert">
                    <strong id="actualCostAlert" class="ml-1 actual-cost-alert"><font-awesome-icon :icon="['far', 'circle-exclamation']"/></strong>
                    <b-popover target="actualCostAlert" triggers="hover" placement="topleft">
                      {{ actualCostAlertMsg }}
                    </b-popover>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['actualCost'] != null">
              <b-col v-for="(field, index) in customFieldMap['actualCost']" :key="'actualCost'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          
            <b-col cols="12" class="pr-0" v-if="isRebateVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`task.field.rebates`) }}</label>
                <button v-if="!isRebateReadOnly" id="REBATE_ADD" class="btn-action" @click="rebateSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="REBATE_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.rebate_add')">
                  </b-popover>
                </button>

                <b-form-text class="rebate-total mr-1">
                      {{ $t(`task.field.total_rebate`, [totalRebate]) }}
                </b-form-text>
                <BadgeGroup v-model="rebates" :readOnly="isRebateReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="rebateBadgeRemove(index)" @badgeClick="rebateBadgeClick(item.uuId)"
                      :text="`${item.name} (${formatRebate(item.rebate)}%)`" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index"
                      :readOnly="isRebateReadOnly" />
                    </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['rebates'] != null">
              <b-col v-for="(field, index) in customFieldMap['rebates']" :key="'rebates'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isStageVisible">
              <b-form-group>
                <StageList :holderId="id" :stages="stages" @modified="stagesModified" :readOnly="isStageReadOnly" filterEntity="TASK"/>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['taskStages'] != null">
              <b-col v-for="(field, index) in customFieldMap['taskStages']" :key="'taskStages'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isStorageFileVisible">
              <b-form-group>
                <label class="mr-1">{{ $t(`project.field.files`) }}</label>
                <button v-if="!isStorageFileReadOnly" id="FILE_ADD" class="btn-action" @click="fileSelectorToggle"><font-awesome-icon :icon="['far', 'plus']"/>
                  <b-popover
                    target="FILE_ADD"
                    placement="top"
                    triggers="hover"
                    :content="$t('project.button.file_add')">
                  </b-popover>
                </button>
                <BadgeGroup v-model="files" :readOnly="isStorageFileReadOnly">
                  <template v-slot:default="{ item, index }">
                    <Badge @badgeRemove="fileBadgeRemove(index)" @badgeClick="fileBadgeClick(item)"
                      :text="labelFilename(item.name, item.type)" 
                      variant="primary" 
                      :pillable="!!item.pillable" :key="index" 
                      :readOnly="isStorageFileReadOnly"
                      enableClickWhenReadOnly
                    />
                  </template>
                </BadgeGroup>
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['files'] != null">
              <b-col v-for="(field, index) in customFieldMap['files']" :key="'files'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" class="pr-0" v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>

            <template v-if="customFieldMap['notes'] != null">
              <b-col v-for="(field, index) in customFieldMap['notes']" :key="'notes'+index" cols="12" class="pr-0">
                <b-form-group>
                  <template v-if="field.type !== 'Boolean'" slot="label">
                    <span class="mr-2">{{ field.displayName }}</span>
                    <span v-if="field.description">
                      <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                      <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                        {{ field.description }}
                      </b-popover>  
                    </span>
                  </template>
                  <CustomField v-model="project[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
        
      </template>
        
      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!isAccessDenied && exists && originProject != null" size="sm" variant="secondary" @click="preScheduleRedirect" style="margin-right: auto">
          {{ $t('task.button.edit_schedule') }}
        </b-button>
        <template v-if="!isAccessDenied && (canEdit() || !exists)">
          <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
            <b-spinner small type="grow" />{{ $t('button.saving') }}
          </b-button>
          <b-button size="sm" variant="success" v-else @click="ok">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('project.confirmation.title_unsaved_change')"
        v-model="state.confirmUnsavedChangeShow"
        :ok-title="$t('button.continue')"
        @ok="confirmUnsavedChangeOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('project.confirmation.unsaved_change') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmUnsavedChangeOk">{{ $t('button.continue') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('project.confirmation.title_apply_rebates')"
        v-model="promptApplyRebates"
        :ok-title="$t('button.yes')"
        :cancel-title="$t('button.no')"
        @ok="applyRebates"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('project.confirmation.apply_rebates') }}</p>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="applyRebates">{{ $t('button.yes') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.no') }}</b-button>
      </template>
    </b-modal>

    <CustomerSelectorModal v-if="state.customerSelectorShow" :show.sync="state.customerSelectorShow" @ok="customerSelectorOk"/>
    <CustomerModal v-if="state.customerEditShow" :id="customerEditId" :show.sync="state.customerEditShow" :title="customerTitle" :readOnly="isReadOnly"/>
    
    <StageSelectorModal v-if="state.statusSelectorShow" :holderId="project.uuId" :show.sync="state.statusSelectorShow" @ok="statusSelectorOk" :multiple="false" :tagFilter="{ entity: 'PROJECT', tags: tagNames }"/>
    <StageModal v-if="state.statusEditShow" :id="statusEditId" :show.sync="state.statusEditShow" :title="statusTitle" :readOnly="isReadOnly"/>

    <CompanySelectorModal v-if="state.companySelectorShow" :show.sync="state.companySelectorShow" @ok="companySelectorOk"/>
    <CompanyModal v-if="state.companyEditShow" :id="companyEditId" :show.sync="state.companyEditShow" :title="companyTitle" :readOnly="isReadOnly" @success="companyEditOk"/>
    
    <FileSelectorModal v-if="state.fileSelectorShow" :show.sync="state.fileSelectorShow" @ok="fileSelectorOk"/>
    
    <RebateSelectorModal v-if="state.rebateSelectorShow" :show.sync="state.rebateSelectorShow" @ok="rebateSelectorOk"/>

    <RebateModal v-if="state.rebateEditShow" :show.sync="state.rebateEditShow" :id="rebateEdit.uuId" @success="rebateEditOk"/>
    
    <LocationSelectorModal v-if="state.locSelectorShow" :show.sync="state.locSelectorShow" @ok="locSelectorOk" :multiple="false"/>
    <DownloadProgressModal v-if="downloadProgressShow" :show.sync="downloadProgressShow" :downloadPercentage="downloadPercentage" @cancel="downloadCancel"/>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="PROJECT" :customFields="customFields" links="COMPANY,CUSTOMER,LOCATION,NOTE,REBATE,STAGE,STORAGE_FILE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
  </div>
</template>

<script>
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { updateFiles } from '@/helpers/file';
import { updateLocation } from '@/helpers/location';
import { updateRebates } from '@/helpers/rebate';
import { updateCustomers } from '@/helpers/customer';
import { updateCompanies } from '@/helpers/company';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { cloneDeep } from 'lodash';
import { strRandom, forceFileDownload, labelFilename, 
         onDurationKeyDown, onDurationKeyUp, incrementDuration, processRegExp
} from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { projectService, projectLinkCustomerService, projectLinkLocationService,
         projectLinkFileService, projectLinkStageService,
         projectLinkTagService, 
         projectLinkRebateService, projectLinkCompanyService,
         projectLinkStatusService } from '@/services';
import { floatFormatter, costFormat, costFormatAdv,
         loadViewProfile, updateViewProfile  
} from '@/helpers';
import { toFixed, convertDurationToDisplay, convertDisplayToDuration } from '@/helpers/task-duration-process';
import { scheduleMode } from "@/selectOptions";
import { removeDeniedProperties } from '@/views/management/script/common';
import currencies from '@/views/management/script/currencies';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'ProjectModal',
  components: {
    AvatarBanner: () => import('@/components/AvatarBanner/AvatarBanner'),
    LocationSelectorModal: () => import('@/components/modal/LocationSelectorModal'),
    CustomerSelectorModal: () => import('@/components/modal/CustomerSelectorModal'),
    CustomerModal: () => import('@/components/modal/CustomerModal'),
    CompanySelectorModal: () => import('@/components/modal/CompanySelectorModal'),
    CompanyModal: () => import('@/components/modal/CompanyModal'),
    FileSelectorModal: () => import('@/components/modal/FileSelectorModal'),
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    DownloadProgressModal: () => import('@/components/modal/DownloadProgressModal'),
    StageList: () => import('@/components/Stage/StageList.vue'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    RebateModal: () => import('@/components/modal/RebateModal'),
    RebateSelectorModal: () => import('@/components/modal/RebateSelectorModal'),
    StageSelectorModal: () => import('@/components/modal/StageSelectorModal'),
    StageModal: () => import('@/components/modal/StageModal'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: `PROJECT_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'PROJECT',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      settings: {},
      state: {
        editable:     false,
        isSubmitting: false,
        modalShow:    false,
        locSelectorShow:      false,
        confirmUnsavedChangeShow: false,
        customerSelectorShow: false,
        statusSelectorShow: false,
        companySelectorShow: false,
        companyEditShow: false,
        customerEditShow: false,
        fileSelectorShow: false,
        historyShow:      false,
        rebateSelectorShow: false,
        rebateEditShow:     false,
        statusEditShow:     false,
        noteShow:           false
      },
      avatarBanner: {
        avatarId: null,
        bannerId: null
      },
      project: {
        uuId:         null,
        name:         null,
        description:  null,
        scheduleStart:  null,
        scheduleFinish: null,
        priority:       null,
        autoScheduling: false,
        scheduleMode:   'ASAP',
        fixedCost:      null,
        fixedCostNet: null,
        currencyCode:   null,
        estimatedCost:           0.0,
        estimatedCostNet: 0.0,
        actualCost:              0.0,
        actualCostNet:    0.0,
        identifier:     null,
        color:          null,
        fixedDuration: null,
        actualDuration: null,
        estimatedDuration: null
      },
      
      location: [],
      customers: [
        // { uuId: "1", name: "Paramount" }
      ],
      company: [],
      companyEditId: null,
      customerEditId: null,
      stages: [],
      tags: [],
      files: [],
      
      downloadProgressShow: false,
      downloadPercentage: 0,
      downloadCancelTokenSource: null,
      downloadCancelled: false,
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      
      rebates: [],
      rebateEdit: {
        uuId: null,
        name: null,
        rebate: null
      },
      actualCostMode: 'gross',
      fixedCostMode: 'gross',
      estimatedCostMode: 'gross',

      // we only store one, but use badge style, so copy other badges
      statuses: [],
      statusEditId: null,
      updatedColor: null,
      isAccessDenied: false,

      priorityOptions: [],

      isEnumListReady: false,
      isProfileSettingReady: false,
      promptApplyRebates: false,
      
      customFields: [],
      customFieldMap: {},

      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    if (this.show) {
      this.getEnumList(() => {
        if (this.project.currencyCode == null && this.currencyOpts.find(i => i.num > -1) != null) {
          this.project.currencyCode = this.currencyOpts.find(i => i.num > -1).value
        }
      });
    }
    this.scheduleDateGroup = ['scheduleStart', 'scheduleFinish']
    this.originAvatarBanner = null;
    this.originProject = null;
    if(this.exists) {
      this.projectGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.originCustomers = [];
    this.originFiles = [];
    this.originStages = [];
    this.originTags = [];
    this.originRebates = [];
    this.originCompany = [];
    this.originStatuses = [];
    this.original = {
      description: null
    }
    this.originNotes = [];
    
    this.currencyOpts = [];
    this.floatFormatter = floatFormatter;

    //Disable validation to prevent js error raised by 'required' validation.
    //It will be enabled when show state is true.
    this.$validator.pause();
  },
  mounted() {
    this.processWhenShowStateChanged(this.show);
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originAvatarBanner = null;
    this.originProject = null;
    this.originCustomers = null;
    this.originFiles = null;
    this.originStages = null;
    this.originTags = null;
    this.originRebates = null;
    this.originCompany = null;
    this.originStatuses = null;
    
    this.currencyOpts = null;
    this.floatFormatter = null;
    this.original = null;
    this.originNotes = null;
    this.scheduleDateGroup = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `PROJECT_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly;
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'project.name');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'project.description');
    },
    exists() {
      return this.id && !this.id.startsWith('PROJECT_NEW_');
    },
    labelTitle() {
      return this.title? this.title: this.$t('project.title_new');
    },
    showCompanyError() {
      return fieldValidateUtil.hasError(this.errors, 'staff.company');
    },
    showLocationError() {
      return fieldValidateUtil.hasError(this.errors, 'location');
    },
    optionscheduleMode() {
      return scheduleMode(this);
    },
    customerTitle() {
      return this.isReadOnly? this.$t('project.title_customer_view') : this.$t('project.title_customer_edit');
    },
    companyTitle() {
      return this.isReadOnly? this.$t('project.title_company_view') : this.$t('project.title_company_edit');
    },
    estimatedCost() {
      return this.project.currencyCode == null || this.project.currencyCode.trim().length == 0? 
        `$${costFormat(this.project.estimatedCost)}` : costFormatAdv(this.project.estimatedCost, this.project.currencyCode);
    },
    statusTitle() {
      return this.isReadOnly? this.$t('project.title_stage_view') : this.$t('project.title_stage_edit');
    },
    estimatedCostNet() {
      return this.project.currencyCode == null || this.project.currencyCode.trim().length == 0? 
        `$${costFormat(this.project.estimatedCostNet)}` : costFormatAdv(this.project.estimatedCostNet, this.project.currencyCode);
    },
    actualCost() {
      return this.project.currencyCode == null || this.project.currencyCode.trim().length == 0? 
        `$${costFormat(this.project.actualCost)}` : costFormatAdv(this.project.actualCost, this.project.currencyCode);
    },
    actualCostNet() {
      return this.project.currencyCode == null || this.project.currencyCode.trim().length == 0? 
        `$${costFormat(this.project.actualCostNet)}` : costFormatAdv(this.project.actualCostNet, this.project.currencyCode);
    },
    actualCostAlert() {
      if(this.project && this.project.actualCost != null) {
        const fixedCost = this.project.fixedCost ? this.project.fixedCost : 0;
        if(this.project.actualCost > fixedCost && fixedCost !== 0) {
          return true;
        } else if(this.project.estimatedCost && this.project.actualCost > this.project.estimatedCost) {
          return true;
        }
      }
      return false;
    },
    actualCostAlertMsg() {
      if(this.project && this.project.actualCost != null) {
        const fixedCost = this.project.fixedCost ? this.project.fixedCost : 0;
        if(this.project.actualCost > fixedCost && fixedCost !== 0) {
          return this.$t('project.alert.actual_cost_exceeds_fixed_cost');
        } else if(this.project.estimatedCost && this.project.actualCost > this.project.estimatedCost) {
          return this.$t('project.alert.actual_cost_exceeds_estimated_cost');
        }
      }
      return '';
    },
    showCurrencyError() {
      return fieldValidateUtil.hasError(this.errors, 'project.currencyCode');
    },
    actualCostClass() {
      if ((this.project.fixedCost && this.project.actualCost > this.project.fixedCost) ||
          (this.project.estimatedCost < this.project.actualCost)) {
        // return 'colorRed';
        return true;
      }
      return false;
      // return '';
    },
    totalRebate() {
      var total = 0;
      for (const rebate of this.rebates) {
        total += rebate.rebate;
      }
      return toFixed(total*100, 2);
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxDescriptionLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "description";
      });
      return values.length !== 0 ? values[0].max : 10000;
    },
    isAvatarBannerVisible() {
      return this.canView('STORAGE_FILE') && this.canView(this.permissionName, ['avatarRef', 'bannerRef']) && 
      ((!this.exists && this.canAdd(this.permissionName, ['avatarRef', 'bannerRef'])) || this.exists);
    },
    isAvatarBannerReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['avatarRef', 'bannerRef']));
    },
    isCompanyVisible() {
      //ParentCompany is mandatory field in business logic
      return this.canView('COMPANY', ['PROJECT'])
    },
    isCompanyReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit('COMPANY', ['PROJECT']))
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isColorVisible() {
      return this.canView(this.permissionName, ['color']) && ((!this.exists && this.canAdd(this.permissionName, ['color'])) 
      || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isLocationVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('LOCATION') && this.canView(this.permissionName, ['LOCATION']) && ((!this.exists && this.canEdit(this.permissionName, ['LOCATION']))
      || this.exists)
    },
    isLocationReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['LOCATION'])
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    isDurationVisible() {
      return this.canView(this.permissionName, ['estimatedDuration']);
    },
    disableFixedDuration() {
      return this.exists && !this.canEdit(this.permissionName, ['fixedDuration']);
    },
    isFixedDurationVisible() {
      return this.canView(this.permissionName, ['fixedDuration']) 
      && ((!this.exists && this.canAdd(this.permissionName, ['fixedDuration'])) || this.exists)
    },
    isPriorityVisible() {
      return this.canView(this.permissionName, ['priority']) && ((!this.exists && this.canAdd(this.permissionName, ['priority'])) 
      || this.exists)
    },
    isPriorityReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['priority']))
    },
    isStageVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('STAGE') && this.canView(this.permissionName, ['STAGE']) && ((!this.exists && this.canEdit(this.permissionName, ['STAGE']))
      || this.exists)
    },
    isStageReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['STAGE'])
    },
    isScheduleDateGroupReadOnly() {
      return this.isReadOnly ||
        (!this.canView(this.permissionName, this.scheduleDateGroup) 
          || (this.exists && !this.canEdit(this.permissionName, this.scheduleDateGroup))
        )
    },
    isScheduleStartVisible() {
      return this.canView(this.permissionName, ['scheduleStart']) && ((!this.exists && this.canAdd(this.permissionName, ['scheduleStart'])) 
      || this.exists)
    },
    isScheduleStartReadOnly() {
      return this.isScheduleDateGroupReadOnly
    },    
    isScheduleFinishVisible() {
      return this.canView(this.permissionName, ['scheduleFinish']) && ((!this.exists && this.canAdd(this.permissionName, ['scheduleFinish'])) 
      || this.exists)
    },
    isScheduleFinishReadOnly() {
      return this.isScheduleDateGroupReadOnly
    },
     isAutoSchedulingVisible() {
      return this.canView(this.permissionName, ['autoScheduling']) && ((!this.exists && this.canAdd(this.permissionName, ['autoScheduling'])) 
      || this.exists)
    },
    isAutoSchedulingReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['autoScheduling']))
    },
    isScheduleModeVisible() {
      return this.canView(this.permissionName, ['scheduleMode']) && ((!this.exists && this.canAdd(this.permissionName, ['scheduleMode'])) 
      || this.exists)
    },
    isFixedDurationReadOnly() {
      return this.isReadOnly ||
        (!this.canView(this.permissionName, ['fixedDuration']) 
          || (this.exists && !this.canEdit(this.permissionName, ['fixedDuration'])) 
          || (!this.exists && !this.canAdd(this.permissionName, ['fixedDuration']))
        )
    },
    showFixedDurationError() {
      return fieldValidateUtil.hasError(this.errors, 'fixedDuration');
    },
    fixedDurationAlert() {
      if (this.project.fixedDuration === null) {
        return false;
      }
      
      const { value: dValue } = convertDisplayToDuration(this.project.estimatedDuration);
      const { value: fixedDuration } = convertDisplayToDuration(this.project.fixedDuration);
      const { value: actualDuration } = convertDisplayToDuration(this.project.actualDuration);
      return fixedDuration < dValue || fixedDuration < actualDuration;
    },
    fixedDurationAlertMsg() {
      if(this.project && this.project.fixedDuration != null) {
        const { value: dValue } = convertDisplayToDuration(this.project.estimatedDuration);
        const { value: fixedDuration } = convertDisplayToDuration(this.project.fixedDuration);
        const { value: actualDuration } = convertDisplayToDuration(this.project.actualDuration);
        if(actualDuration > fixedDuration) {
          const diff = convertDurationToDisplay(actualDuration - fixedDuration, 'D');
          return this.$t('task.alert.actual_duration_exceeds_fixed_duration', [diff]);
        } else if(dValue > fixedDuration) {
          const diff = convertDurationToDisplay(dValue - fixedDuration, 'D');
          return this.$t('task.alert.estimated_duration_exceeds_fixed_duration', [diff]);
        }
      }
      return '';
    },
    fixedDurationClass() {
      return { 
        colorRed: this.fixedDurationAlert,
        'field-readonly-no-color-filter': !this.disableFixedDuration && !this.isReadOnly
      }
    },
    isScheduleModeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['scheduleMode']))
    },
    isRebateVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('REBATE') && this.canView(this.permissionName, ['REBATE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['REBATE'])) || this.exists)
    },
    isRebateReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['REBATE'])
    },
    isStorageFileVisible() {
      //Link creation to check against canEdit()
      return this.canView(this.permissionName, ['STORAGE_FILE']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['STORAGE_FILE'])) || this.exists)
    },
    isStorageFileReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['STORAGE_FILE']))
    },
    isFixedCostVisible() {
      return this.canView(this.permissionName, ['fixedCost'])
      && ((!this.exists && this.canAdd(this.permissionName, ['fixedCost'])) || this.exists)
    },
    isFixedCostNetVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['fixedCostNet'])
      && this.exists
    },
    isFixedCostReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['fixedCost']))
    },
    isCurrencyCodeVisible() {
      return !this.isTemplate && this.canView(this.permissionName, ['currencyCode'])
      && ((!this.exists && this.canAdd(this.permissionName, ['currencyCode'])) || this.exists)
    },
    isCurrencyCodeReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['currencyCode']))
    },
    isEstimatedCostVisible() {
      return this.canView(this.permissionName, ['estimatedCost'])
      && this.exists
    },
    isEstimatedCostNetVisible() {
      return this.canView(this.permissionName, ['estimatedCostNet'])
      && this.exists
    },
    isActualCostVisible() {
      return this.canView(this.permissionName, ['actualCost'])
      && this.exists
    },
    isActualCostNetVisible() {
      return this.canView(this.permissionName, ['actualCostNet'])
      && this.exists
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description'])
      && ((!this.exists && this.canAdd(this.permissionName, ['description'])) || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },
    isCustomerVisible() {
      //Link creation requires main entity's edit permission
      return this.canView('CUSTOMER') && this.canView(this.permissionName, ['CUSTOMER']) 
      && ((!this.exists && this.canEdit(this.permissionName, ['CUSTOMER'])) || this.exists)
    },
    isCustomerReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['CUSTOMER'])
    },
    tagNames() {
      return this.tags != null && this.tags.length > 0 ? this.tags.map(i => i.name) : []
    },
    isActualDurationVisible() {
      return this.canView(this.permissionName, ['actualDuration']);
    }
  },
  watch: {
    async show(newValue) {
      if(newValue != this.state.modalShow) {
        this.processWhenShowStateChanged(newValue);
      }
    }
  },
  methods: {
    async processWhenShowStateChanged(newValue) {
      if (newValue) {
        await getCustomFieldInfo(this, 'PROJECT', 'PROJECT');
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
      }
      this.resetProjectProperties();
      if (newValue) {
        const requests = [
          this.getEnumList()
          , loadViewProfile(this.settings, this.$store.state.authentication.user.uuId)
        ]

        Promise.allSettled(requests).then(responses => {
          const projectResponse = responses[1]
          if (projectResponse.status == 'fulfilled') {
            this.settings = projectResponse.value
            if (this.settings.currency && this.currencyOpts.find(i => i.num > -1 && i.value == this.settings.currency) != null) {
              this.project.currencyCode = this.settings.currency;
            }
            if (typeof this.settings.autoScheduling !== 'undefined') {
              this.project.autoScheduling = this.settings.autoScheduling;
            }
          }

          if (this.project.currencyCode == null) {
            const curObj = this.currencyOpts.find(i => i.num > -1);
            this.project.currencyCode = curObj != null? curObj.value : null;
          }
        })
        this.$validator.resume();
      } else {
        this.$validator.pause();
      }
      
      this.state.modalShow = newValue;
      this.state.noteShow = false;
      this.originNotes = [];
      this.notes = [];
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
      this.actualCostMode = this.isActualCostVisible? 'gross' : 'net';
      this.fixedCostMode = this.isFixedCostVisible? 'gross' : 'net';
      this.estimatedCostMode = this.isEstimatedCostVisible? 'gross' : 'net';
      this.restrictedRequiredField = null;
      if(this.exists) {
        this.projectGet(this.id);
      } else {
        if (newValue) { 
          const requiredFields = ['name']
          const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
          if (requiredCustomFields.length > 0) {
            requiredFields.push(...requiredCustomFields);
          }
          let result = this.canView2(this.permissionName, requiredFields);
          if (result.val) {
            result = this.canAdd2(this.permissionName, requiredFields)
          } 

          if (result.val && !(this.canView('COMPANY', ['PROJECT']) && this.canEdit('COMPANY', ['PROJECT']))) {
            result = { val: false, restrictedProp: 'COMPANY' }
          } 
          
          if (result.restrictedProp != null) {
            this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
          }

          if (result.val) {
            this.isAccessDenied = false;
          } else {
            this.isAccessDenied = true;
          }
        
        } else {
          this.isAccessDenied = false;
        }
        this.project.scheduleMode = 'ASAP';
      }
    },
    getDisplayNameOfProperty(val) {
      if (val == 'COMPANY') {
        return this.$t('staff.field.company');
      } else {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`project.field.${val}`);
      }
    },
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "api", object: "PROJECT"}).then(value => {
        this.modelInfo = value.PROJECT.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    async getEnumList(callback=null) {
      return this.$store.dispatch('data/enumList').then(response => {
        if (response.jobCase != null && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          if (propertyList != null) {
            if (propertyList.GanttPriorityEnum != null) {
              const obj = propertyList.GanttPriorityEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                list.push({ value: c, text: c, num: obj[c] })
              }
              this.priorityOptions = list
            }
            if (propertyList.CurrencyEnum != null) {
              const obj = propertyList.CurrencyEnum
              const codes = Object.keys(obj)
              const list = []
              for (const c of codes) {
                const found = currencies.find(i => i.code == c)
                const text = found != null && found.name != null? `${c} (${found.name})` : c
                list.push({ value: c, text, num: obj[c] })
              }
              this.currencyOpts = list
            }

            if (typeof callback == 'function') {
              callback()
            }
          }
        }
      }).catch(e => {
        this.httpAjaxError(e);
      });
    },
    formatRebate(rebate) {
      return toFixed(rebate*100, 2);
    },
    labelFilename(name, type) {
      return labelFilename(name, type);
    },
    floatFormatter(value) {
      return value && !isNaN(value)? parseFloat(value).toFixed(2): 0.00;
    },
    projectGet(id) {
      projectService.get([{ uuId: id}], ['COMPANY', 'LOCATION',
          'CUSTOMER', 'REBATE', 'STAGE_LIST', 'STORAGE_FILE', 'NOTE', 'STAGE', 'TAG'], true).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return;
        }
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      this.$validator.pause();
      const p = this.project;
      const dates = ['scheduleStart','scheduleFinish']
      for (const key of Object.keys(p)) {
        if(dates.includes(key)) {
          p[key] = data[key] != null? moment.utc(data[key]).format('YYYY-MM-DD'): null;
        } else {
          p[key] = data[key] != null? data[key] : null;
        }
      }

      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          p[field.name] = data[field.name];
        }
      }
      
      if (p.fixedDuration !== null) {
        p.fixedDuration = convertDurationToDisplay(p.fixedDuration, 'D');
      }
      
      if (p.estimatedDuration !== null) {
        p.estimatedDuration = convertDurationToDisplay(p.estimatedDuration, 'D');
      }
      
      if (p.actualDuration !== null) {
        p.actualDuration = convertDurationToDisplay(p.actualDuration, 'D');
      }
      
      //Setup Location data
      if (data.locationList && data.locationList.length > 0) {
        const list = data.locationList.map(i => { return { uuId: i.uuId, name: i.name }})
        const location = list.length > 0? list[0] : null;
        this.originLocId = location? location.uuId : null;
        if(location) {
          this.location = [{ uuId: location.uuId, name: location.name }];
        }
      }
      else {
        this.location = [];
      }

      p.uuId = data.uuId;
      //Setup avatarBanner data
      const ab = this.avatarBanner;
      if (data.avatarRef || data.bannerRef) {
        ab.avatarId = data.avatarRef;
        ab.bannerId = data.bannerRef;
        if (ab.avatarId == '00000000-0000-0000-0000-000000000000') {
          ab.avatarId = null;
        }
        if (ab.bannerId == '00000000-0000-0000-0000-000000000000') {
          ab.bannerId = null;
        }
      }

      //Setup Customers data
      if (data.customerList && data.customerList.length > 0) {
        const list = data.customerList.map(i => { return { uuId: i.uuId, name: i.name }})
        this.originCustomers.splice(0, this.originCustomers.length, ...list);
        this.customers.splice(0, this.customers.length, ...cloneDeep(list));
      }

      //Setup Company data
      if (data.companyList && data.companyList.length > 0) {
        const list = data.companyList.map(i => { return { uuId: i.uuId, name: i.name }})
        this.originCompany.splice(0, this.originCompany.length, ...list);
        this.company.splice(0, this.company.length, ...cloneDeep(list));
      }
      else {
        this.company = [];
      }

      //Setup Stages data
      if (data.stageList && data.stageList.length > 0) {
        const list = typeof data.stageList !== 'undefined' ? data.stageList : [];
        this.originStages.splice(0, this.originStages.length, ...list);
        this.stages.splice(0, this.stages.length, ...cloneDeep(list));
      }

      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      }

      // Setup rebates data
      if (data.rebateList && data.rebateList.length > 0) {
        const list = data.rebateList;
        this.rebates = cloneDeep(list);
        this.originRebates = cloneDeep(list);
      }
      
      //Setup Files data
      if (data.storageFileList && data.storageFileList.length > 0) {
        const list = data.storageFileList.map(i => { return { uuId: i.uuId, name: i.name, type: i.type }})
        this.originFiles.splice(0, this.originFiles.length, ...list);
        this.files.splice(0, this.files.length, ...cloneDeep(list));
      }

      //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
    
      //Setup Statuses data
      if (data.stage) {
        this.originStatuses = [data.stage];
        this.statuses = [data.stage];
      }

      if(!Object.prototype.hasOwnProperty.call(data, 'autoScheduling')) {
        p.autoScheduling = true;
      } else {
        p.autoScheduling = data.autoScheduling;
      }
      if(!Object.prototype.hasOwnProperty.call(data, 'scheduleMode')) {
        p.scheduleMode = 'ASAP';
      } else {
        p.scheduleMode = data.scheduleMode;
      }

      this.originAvatarBanner = cloneDeep(ab);
      this.originProject = cloneDeep(p);
      
      this.originProject.scheduleStart = data.scheduleStart != null? data.scheduleStart : null;
      this.originProject.scheduleFinish = data.scheduleFinish != null? data.scheduleFinish : null;
      this.originProject.avatarRef = data.avatarRef;
      this.originProject.bannerRef = data.bannerRef;
      this.originProject.durationAUM = data.durationAUM;
      this.$validator.resume();
    },
    validateCurrencyCode() {
      if(!this.project.currencyCode && 
          ((this.exists && this.canEdit(this.permissionName, ['currencyCode'])) || (!this.exists && this.canAdd(this.permissionName, ['currencyCode'])))) {
        this.errors.add({
          field: `project.currencyCode`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('project.field.currencyCode')])
        });
        return false;
      } else {
        return true;
      }
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.project[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      if (this.totalRebate > 100) {
        this.alertMsg = this.$t('rebate.error.total');
        return;
      }
      
      if(this.company.length === 0) {
        this.errors.add({
          field: `staff.company`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.company')])
        });
      }
      
      this.validateCurrencyCode();
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.projectSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    async projectSubmit() {
      const data = cloneDeep(this.project);
      if (this.settings.currency !== this.project.currencyCode ||
          this.settings.autoScheduling !== this.project.autoScheduling) {
        this.settings.currency = this.project.currencyCode;
        this.settings.autoScheduling = this.project.autoScheduling;
        await updateViewProfile(this.settings, this.$store.state.authentication.user.uuId);
      }
      
      data['avatarRef'] = this.avatarBanner.avatarId;
      data['bannerRef'] = this.avatarBanner.bannerId;
      if (data['avatarRef'] == null) {
        data['avatarRef'] = '00000000-0000-0000-0000-000000000000'
      }
      if (data['bannerRef'] == null) {
        data['bannerRef'] = '00000000-0000-0000-0000-000000000000'
      }

      const dates = ['scheduleStart','scheduleFinish'];
      dates.forEach(key => {
        if(data[key]) {
          if (typeof data[key] !== 'string') {
            data[key] = moment(`${data[key].getFullYear()}-${data[key].getMonth() + 1}-${data[key].getDate()}T${data[key].getHours()}:${data[key].getMinutes()}:${data[key].getSeconds()}`, 'YYYY-MM-DDTHH:mm:ss').valueOf();
          }
          else {
            data[key] = moment(data[key]).valueOf();
          }
        }
      })
      
      // data.durationAUM = 'D'; //Default and fixed value
      if (data.fixedCost === null) {
        data.fixedCost = 0;
      }

      if (data.fixedDuration !== null) {
        const { value: fixedDuration } = convertDisplayToDuration(data.fixedDuration);
        data.fixedDuration = fixedDuration;
      }
      
      delete data.fixedCostNet;
      delete data.estimatedCost;
      delete data.estimatedCostNet;
      delete data.actualCost;
      delete data.actualCostNet;

      let method = 'update';
      if(!this.exists) {
        delete data['uuId'];
        method = 'create';
      }
      this.projectPost(method, data);
    },
    async projectPost(method, data) {
      this.state.isSubmitting = true;
      let projectId = null;
      
      //Skip updating project if there is no change in project properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedProjectProperties(data);
      }

      if (method == 'create' || hasChanged) {
        let result = await this.updateProject(method, data);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.state.isSubmitting = false;
          return;
        }
        projectId = result.projectId;
      } else {
        projectId = data.uuId;
      }

      let hasError = false;
      
      if (!this.isLocationReadOnly) {
        const locationResult = await updateLocation(projectId, projectLinkLocationService, [{ uuId: this.originLocId }], this.location);
        if (locationResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (locationResult.errorCodes.length > 0) {
            if (locationResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('location.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('location.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isCompanyReadOnly) {
        const companyResult = await updateCompanies(projectId, projectLinkCompanyService, this.originCompany, this.company)
        if (companyResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (companyResult.errorCodes.length > 0) {
            if (companyResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('customer.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('customer.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('customer.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isCustomerReadOnly) {
        const customerResult = await updateCustomers(projectId, projectLinkCustomerService, this.originCustomers, this.customers)
        if (customerResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (customerResult.errorCodes.length > 0) {
            if (customerResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('customer.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('customer.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('customer.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStageReadOnly) {
        const stageResult = await this.updateStages(projectId);
        if (stageResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (stageResult.errorCodes.length > 0) {
            if (stageResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('project.field.stages').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('project.field.stages').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('project.field.stages').toLowerCase()]))
          }
        }
      }
      
      // save the color in the profile
      this.updatedColor = data.color;
      
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(projectId, projectLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isRebateReadOnly) {
        const rebateResult = await updateRebates(projectId, projectLinkRebateService, this.originRebates, this.rebates)
        if (rebateResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (rebateResult.errorCodes.length > 0) {
            if (rebateResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('rebate.title').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('rebate.title').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStorageFileReadOnly) {
        const fileResult = await updateFiles(projectId, projectLinkFileService, this.originFiles, this.files)
        if (fileResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (fileResult.errorCodes.length > 0) {
            if (fileResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('file.title.list').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('file.title.list').toLowerCase()]))
          }
        }
      }
      
      if (!this.isStageReadOnly) {
        const statusResult = await this.updateStatuses(projectId);
        if (statusResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (statusResult.errorCodes.length > 0) {
            if (statusResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('project.field.status').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('project.field.status').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('project.field.status').toLowerCase()]))
          }
        }
      }
      
      if (!this.isNoteReadOnly) {
        //Notes
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }      
        const noteResult = await persistNotes(projectId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`project.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      this.state.isSubmitting = false;
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`project.${method}`) });
      } else {
        this.scrollToTop();
      }
    },
    async updateProject(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`project.${method}`)
      }

      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');

      let projectId = await projectService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      })
      .catch(e => {
        result.hasError = true;
        result.msg = this.$t(`project.error.failed_to_${method}`);
        const response = e.response;
        if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 
              'string_limit_exceeded', 'number_limit_exceeded',
              'date_limit_exceeded'].includes(clue)) {
            result.msg = this.$t('error.attention_required');
            const fieldKey = `project.${feedback.args[0]}`;
            const args = [this.$t(`project.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
              case 'cannot_be_blank':
                break;
              case 'date_limit_exceeded':
                args.push(moment.utc(feedback.args[1]).format('YYYY-MM-DD'));
                break;
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                result.msg = this.$('error.internal_server'); //reset the msg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          }
        }
      });
      result.projectId = projectId;
      return result;
    },
    async updateStages(projectId) {
      const result = {
        hasError: false,
        errorCodes: []
      }

      let toDelete = [];
      let toAdd = [];
      const stages = cloneDeep(this.stages);
      const originStages = this.originStages;

      // If any stage has been removed from the list, delete the link
      for (let i = 0; i < originStages.length; i++) {
        if (!(stages.map(s => s.uuId).includes(originStages[i].uuId))) {
          toDelete.push({uuId: originStages[i].uuId});
        }
      }
      if (toDelete.length > 0) {
        let { hasError=false, errorCode=null } = await projectLinkStageService.remove(projectId, toDelete)
        .then(response => {
          if (207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failRebate = toAdd.filter(i => failIds.includes(i.uuId));
            return { hasError: failRebate.length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if (e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failedList = toAdd.filter(i => !existingIds.includes(i.uuId));
            return { hasError: failedList.length > 0, errorCode: 422 };
          } else {
            return {
              hasError: true
              , errorCode: e != null && e.response != null ? e.response.status : null
            }
          }
        });

        if (hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      // If any stage has been added to the list, create the link
      for (i = 0; i < stages.length; i++) {
        if (!(originStages.map(s => s.uuId).includes(stages[i].uuId))) {
          toAdd.push({uuId: stages[i].uuId});
        }
      }
      if (toAdd.length > 0) {
        let { hasError=false, errorCode=null } = await projectLinkStageService.create(projectId, toAdd)
        .then(response => {
          if (207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failRebate = toAdd.filter(i => failIds.includes(i.uuId));
            return { hasError: failRebate.length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if (e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failedList = toAdd.filter(i => !existingIds.includes(i.uuId));
            return { hasError: failedList.length > 0, errorCode: 422 };
          } else {
            return {
              hasError: true
              , errorCode: e != null && e.response != null ? e.response.status : null
            }
          }
        });

        if (hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }


      // If the old list and new list are not identical, we need to update the
      // order of stages to the new list order.
      let reorder = false;
      if (stages.length !== originStages.length) {
        reorder = true;
      } else {
        for (var i = 0; i < stages.length; i++) {
          if (stages[i].uuId != originStages[i].uuId) {
            reorder = true;
            break;
          }
        }  
      }
      // Need to have more than one to reorder, or api breaks
      if (stages.length > 1 && reorder) {
        let newOrder = [];
        stages.forEach(stage => { newOrder.push({uuId: stage.uuId})});
        let { hasError=false, errorCode=null } = await projectService.stageOrder(projectId, newOrder)
        .then(response => {
          if (207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failRebate = toAdd.filter(i => failIds.includes(i.uuId));
            return { hasError: failRebate.length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if (e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failedList = toAdd.filter(i => !existingIds.includes(i.uuId));
            return { hasError: failedList.length > 0, errorCode: 422 };
          } else {
            return {
              hasError: true
              , errorCode: e != null && e.response != null ? e.response.status : null
            }
          }
        });

        if (hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }
      return result;
    },
    async updateStatuses(projectId) {
      const result = {
        hasError: false,
        errorCodes: []
      }
      
      const statuses = cloneDeep(this.statuses);
      const originStatuses = this.originStatuses;
      let toDelete = [];
      let toAdd = [];
      // If any status has been removed from the list, delete the link
      for (let i = 0; i < originStatuses.length; i++) {
        if (!(statuses.map(s => s.uuId).includes(originStatuses[i].uuId))) {
          toDelete.push({uuId: originStatuses[i].uuId});
        }
      }
      if (toDelete.length > 0) {
        let { hasError=false, errorCode=null } = await projectLinkStatusService.remove(projectId, toDelete)
        .then(response => {
          if (207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failRebate = toAdd.filter(i => failIds.includes(i.uuId));
            return { hasError: failRebate.length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if (e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failedList = toAdd.filter(i => !existingIds.includes(i.uuId));
            return { hasError: failedList.length > 0, errorCode: 422 };
          } else {
            return {
              hasError: true
              , errorCode: e != null && e.response != null ? e.response.status : null
            }
          }
        });

        if (hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      // If any status has been added to the list, create the link
      for (let i = 0; i < statuses.length; i++) {
        if (!(originStatuses.map(s => s.uuId).includes(statuses[i].uuId))) {
          toAdd.push({uuId: statuses[i].uuId});
        }
      }
      if (toAdd.length > 0) {
        let { hasError=false, errorCode=null } = await projectLinkStatusService.create(projectId, toAdd)
        .then(response => {
          if (207 == response.status) {
            const list = response.data[response.data.jobCase];
            const failIds = list.filter(i => i.clue !== 'Already_have_edge' && i.clue !== 'OK').map(i => i.args[0]);
            const failRebate = toAdd.filter(i => failIds.includes(i.uuId));
            return { hasError: failRebate.length > 0, errorCode: 207 };
          }
          return {};
        })
        .catch(e => {
          if (e.response && 422 == e.response.status) {
            const list = e.response.data[e.response.data.jobCase];
            const existingIds = list.filter(i => i.clue === 'Already_have_edge').map(i => i.args[0]);
            const failedList = toAdd.filter(i => !existingIds.includes(i.uuId));
            return { hasError: failedList.length > 0, errorCode: 422 };
          } else {
            return {
              hasError: true
              , errorCode: e != null && e.response != null ? e.response.status : null
            }
          }
        });

        if (hasError) {
          result.hasError = true;
          result.errorCodes.push(errorCode);
        }
      }

      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(['Missing_argument','Cannot_be_blank', 'Property_limit_exceeded'].includes(feedback.clue)) {
          errorMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `project.${feedback.args[0]}`,
            msg: this.$t(`error.${feedback.clue.toLowerCase()}`, feedback.args)
          })
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetProjectProperties() {
      const keys = Object.keys(this.project);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.project[keys[i]] = customField.def;
            continue;
          }
        }
        this.project[keys[i]] = null;
      }
      
      this.$set(this.project, 'currencyCode', null);
      this.$set(this.project, 'autoScheduling', false);
      this.$set(this.project, 'scheduleMode', 'ASAP');

      this.location = [];
      this.avatarBanner.avatarId = null;
      this.avatarBanner.bannerId = null;

      this.originLocId = null;
      this.originCompany = [];
      this.originCustomers = [];
      this.originAvatarBanner = null;
      this.originProject = null;
      this.customers = [];
      this.customerSelector = [];
      this.files = [];
      this.originFiles = [];
      this.stages = [];
      this.tags = [];
      this.originTags = [];
      this.originStages = [];
      this.originRebates = [];
      this.rebates = [];
      const company = this.$store.state.company;
      if (company) {
        this.company = [company];
        if (company.locationList && company.locationList.length > 0) {
          this.location = [{ uuId: company.locationList[0].uuId, name: company.locationList[0].name }];
        }
      }
      else {
        this.company = [];
        this.location = [];
      }
      this.statuses = [];
      this.originStatuses = [];
    },
    avatarBannerStatus({ alertMsg }) {
      if(alertMsg) {
        this.alertMsg = alertMsg;
      }
    },
    locSelectorOpen() {
      this.state.locSelectorShow = true;
    },
    locSelectorOk({ details }) {
      this.location = [{ uuId: details[0].uuId, name: details[0].name }];
      
      if (details[0].rebates.length > 0) {
        this.rebatesToApply = [];
        for (const r of details[0].rebates) {
          if (this.rebates.findIndex(reb => reb.uuId === r.uuId) === -1) {
            this.promptApplyRebates = true;
            this.rebatesToApply.push(r);
          } 
        }
      }
    },
    rebateSelectorToggle() {
      this.state.rebateSelectorShow = true;
    },
    rebateSelectorOk({details}) {
      for (const rebate of details) {
        const index = this.rebates.findIndex((r) => { return r.uuId === rebate.uuId });
        if (index === -1) {
          this.rebates.push(rebate);
        } 
      }
    },
    rebateEditOk(result) {
      const rebate = result.data;
      const index = this.rebates.findIndex(i => i.uuId === rebate.uuId);
      if (index !== -1) {
        this.rebates.splice(index, 1, rebate);
      }
    },
    rebateBadgeRemove: function(index) {
      this.rebates.splice(index,1)
    },
    rebateBadgeClick(id) {
      const selected = this.rebates.find(i => i.uuId === id);
      const edit = this.rebateEdit;
      edit.uuId = selected.uuId;
      edit.name = selected.name;
      edit.rebate = selected.rebate;
      this.state.rebateEditShow = true;
    },
    // async updateLocation(projectId) {
    //   console.log('updateLocation Logic 1')
    //   const result = {
    //     hasError: false,
    //     msgs: this.$t('project.update_location')
    //   }
    //   const originLocId = this.originLocId;
    //   const locId = this.location.length === 1 ? this.location[0].uuId : null;
    //   const clues = ['Unknown_target', 'Unknown_relation']
    //   console.log('originLocId', originLocId, 'locId', locId)
    //   if(originLocId && originLocId !== locId) {
    //     await projectLinkLocationService.remove(projectId, [originLocId])
    //     .catch(e => {
          
    //       if(e.response && 422 == e.response.status) {
    //         const list = e.response.data[e.response.data.jobCase];
    //         if(!clues.includes(list[0].clue)) {
    //           result.hasError = true;
    //           result.msg = this.$t('project.error.failed_to_update_location');
    //         }
    //       } else {
    //         result.hasError = true
    //         result.msg = this.$t('project.error.failed_to_update_location');
    //       }
    //     });
    //   }
    //   if(result.hasError) {
    //     return result;
    //   }

    //   if(locId && originLocId !== locId) {
    //     await projectLinkLocationService.create(projectId, [locId])
    //     .catch(e => {
    //       if(e.response && 422 == e.response.status) {
    //         const list = e.response.data[e.response.data.jobCase];
    //         if('Already_have_edge'!== list[0].clue) {
    //           result.hasError = true;
    //           result.msg = this.$t('project.error.failed_to_update_location');
    //         }
    //       } else {
    //         result.hasError = true
    //         result.msg = this.$t('project.error.failed_to_update_location');
    //       }
    //     });
    //   }

    //   return result;
    // },
    preScheduleRedirect() {
      //0. Check if unsaved data
      //1. yes, ask if to proceed without saving.
      //1.1 yes, redirect.
      //2. no, do nothing. 

      if(checkDataChange(this)) {
        this.state.confirmUnsavedChangeShow = true;
      } else {
        this.scheduleRedirect();
      }
      
      function checkDataChange(self) {
        const origProject = self.originProject;
        const curProject = cloneDeep(self.project);

        curProject.scheduleFinish = curProject.scheduleFinish != null? moment.utc(curProject.scheduleFinish, 'YYYY-MM-DD').valueOf() : null;
        curProject.scheduleStart = curProject.scheduleStart  != null? moment.utc(curProject.scheduleStart, 'YYYY-MM-DD').valueOf() : null;
        curProject.avatarRef = self.avatarBanner.avatarId != null? self.avatarBanner.avatarId : '00000000-0000-0000-0000-000000000000';
        curProject.bannerRef = self.avatarBanner.bannerId != null? self.avatarBanner.bannerId : '00000000-0000-0000-0000-000000000000';
        delete origProject.durationAUM; //durationAUM is internal property. Exclude it from comparison.

        let isChanged = false;
        for(const key in origProject) {
          if(origProject[key] !== curProject[key]) {
            isChanged = true;
            break;
          }
        }
        if(isChanged) {
          return true;
        }

        return isChanged;
      }
    },
    confirmUnsavedChangeOk() {
      this.state.confirmUnsavedChangeShow = false;
      this.scheduleRedirect();
    },
    scheduleRedirect() {
      this.$router.push(`/projects/tasks/${this.id}`);
    },
    customerBadgeRemove: function(index) {
      this.customers.splice(index,1);
    },
    customerBadgeClick(id) {
      this.customerEditId = id;
      this.state.customerEditShow = true;
    },
    statusBadgeRemove: function(index) {
      this.statuses.splice(index,1);
    },
    statusBadgeClick(id) {
      this.statusEditId = id;
      this.state.statusEditShow = true;
    },
    companyBadgeRemove: function(index) {
      this.company.splice(index,1);
    },
    companyBadgeClick(id) {
      this.companyEditId = id;
      this.state.companySelectorShow = true;
    },
    fileBadgeRemove: function(index) {
      this.files.splice(index,1);
    },
    fileBadgeClick(item) {
      // file download
      this.downloadProgressShow = true;
      forceFileDownload([{ uuId: item.uuId, name: labelFilename(item.name, item.type), type: item.type }], this);
    },
    customerSelectorToggle() {
      this.state.customerSelectorShow = true;
    },
    customerSelectorOk({ details }) {
      const currentIds = this.customers.map(i => i.uuId);
      const newCustomers = details.filter(i => !currentIds.includes(i.uuId));

      for(const c of newCustomers) {
        this.customers.push( {uuId: c.uuId, name: c.name} );
        if (c.color) {
          this.project.color = c.color;
        }
      }
    },
    statusSelectorToggle() {
      this.state.statusSelectorShow = true;
    },
    statusSelectorOk({ details }) {
      var stage = details[0];
      this.statuses = [{uuId: stage.uuId, name: stage.name}];
    },
    companySelectorToggle() {
      this.state.companySelectorShow = true;
    },
    companySelectorOk({ details }) {
      const companies = this.company;
      const currentIds = companies.map(i => i.uuId);
      const oldcompanies = this.companyEditId ? [{ uuId: this.companyEditId }] : [];
      
      oldcompanies.forEach(i => {
        const id = i.uuId;
        const oldId = i.oldId;
        if(id != oldId) {
          if(!currentIds.includes(id)) {
            companies.splice(companies.findIndex(j => j.uuId === oldId), 1, { uuId: i.uuId, name: i.name });
          } else {
            companies.splice(companies.findIndex(j => j.uuId === oldId), 1);
          }
        } else {
          const index = companies.findIndex(j => j.uuId === id);
          companies[index].uuId = i.uuId;
          companies[index].name = i.name;
        }
      })

      const remainingcompanies = details.filter(i => !Object.prototype.hasOwnProperty.call(i, 'oldId'));

      remainingcompanies.forEach(i => {
        const id = i.uuId;
        if(currentIds.includes(id)) {
          const index = companies.findIndex(j => j.uuId === id);
          companies[index].uuId = i.uuId;
          companies[index].name = i.name;
        } else {
          companies.push({ uuId: i.uuId, name: i.name });
        }
      })
      this.companyEditId = null;
    },
    fileSelectorToggle() {
      this.state.fileSelectorShow = true;
    },
    fileSelectorOk(details) {
      const currentIds = this.files.map(i => i.uuId);
      const newFiles = details.filter(i => !currentIds.includes(i.uuId));

      for(const c of newFiles) {
        this.files.push( {uuId: c.uuId, name: c.name, type: c.type} );
      }
    },
    downloadCancel() {
      if(this.downloadCancelTokenSource) {
        this.downloadCancelled = true;
        this.downloadCancelTokenSource.cancel();
      }
    },
    stagesModified({stages}) {
      this.stages = stages;
    },
    tagsModified({tags}) {
      this.tags = tags;
    },
    companyEditOk(result) {
      const company = result.data;
      const index = this.company.findIndex(i => i.uuId === company.uuId);
      if (index !== -1) {
        this.company.splice(index, 1, company);
      }
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedProjectProperties(data) {
      //Remove those properties whose value is not changed in provided data against original project.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalProject = this.originProject;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalProject[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    locationBadgeRemove: function(index) {
      this.location.splice(index,1);
    },
    locationBadgeClick(/*id*/) {
      this.state.locSelectorShow = true;
    },
    onFixedDurationKeyDown(event) {
      onDurationKeyDown(event, false);
    },
    onFixedDurationKeyUp(event) {
      this.project.fixedDuration = onDurationKeyUp(event, this.project.fixedDuration);
    },
    onFixedDurationChange() {
      if (this.project.fixedDuration &&
        // eslint-disable-next-line
      !/^\d+[\.,D,m,h,W,M,Y]+$/.test(this.project.fixedDuration)) {
        this.project.fixedDuration = `${this.project.fixedDuration}D`;
      }
    },
    fixedDurationAddMinus(delta) {
      this.project.fixedDuration = incrementDuration(this.project.fixedDuration ? this.project.fixedDuration : '0D', delta);
    },
    applyRebates() {
      this.rebates.push(...this.rebatesToApply);
      this.rebatesToApply = null;
      this.promptApplyRebates = false;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>

<style lang="scss" scoped>

.colorRed {
  color: var(--status-alt-red) !important;
}
.actual-cost-alert {
  color: var(--status-alt-red);
  position: absolute;
  right: 11px;
  top: 8px;
}

.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}

.color-container {
  position: relative;
}

.forced-border-radius {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
</style>